import { createFeature, createReducer, on } from '@ngrx/store';
import { sensitiveDataToggleActions } from './actions/sensitive-data-toggle.actions';

export interface SensitiveDataToggleState {
  sensitiveDataHidden: boolean;
  dismissedNotification: boolean;
}

export const initialState: SensitiveDataToggleState = {
  sensitiveDataHidden: true,
  dismissedNotification: false,
};

export const sensitiveDataFeature = createFeature({
  name: 'sensitiveDataToggle',
  reducer: createReducer(
    initialState,
    on(sensitiveDataToggleActions.syncSensitiveSuccessfully, (state, { visibility }) => ({
      ...state,
      sensitiveDataHidden: visibility,
    })),
    on(sensitiveDataToggleActions.dismissNotification, (state) => ({ ...state, dismissedNotification: true })),
    on(sensitiveDataToggleActions.syncNotificationSuccessfully, (state, { dismissed }) => ({ ...state, dismissedNotification: dismissed })),
  ),
});
