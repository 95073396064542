import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const sensitiveDataToggleActions = createActionGroup({
  source: 'Sensitive Data Toggle',
  events: {
    'Load Sensitive Data Option': emptyProps(),
    'Sync Sensitive Data': emptyProps(),
    'Sync Sensitive Successfully': props<{ visibility: boolean }>(),
    'Dismiss Notification': emptyProps(),
    'Sync Notification Successfully': props<{ dismissed: boolean }>(),
  },
});
