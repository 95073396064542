import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { sensitiveDataToggleActions } from './actions/sensitive-data-toggle.actions';
import { map, tap, withLatestFrom } from 'rxjs';
import { SENSITIVE_DATA_TOGGLE_ADAPTER } from './sensitive-data-toggle-adapter.token';
import { selectNotificationState, selectSensitiveDataVisibility } from './sensitive-data-toggle.selectors';

@Injectable()
export class SensitiveDataToggleEffects {
  private store = inject(Store);

  private actions$ = inject(Actions);

  private dataAdapter = inject(SENSITIVE_DATA_TOGGLE_ADAPTER);

  loadSensitiveDataState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sensitiveDataToggleActions.loadSensitiveDataOption),
      withLatestFrom(this.store.select(selectSensitiveDataVisibility)),
      map(([, visibilityState]) => {
        const visibility = this.dataAdapter.loadSensitiveDataState();
        return sensitiveDataToggleActions.syncSensitiveSuccessfully({ visibility: visibility === null ? visibilityState : visibility });
      }),
    ),
  );

  syncSensitiveDataState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sensitiveDataToggleActions.syncSensitiveData),
      withLatestFrom(this.store.select(selectSensitiveDataVisibility)),
      tap(([, visibilityState]) => this.dataAdapter.syncSensitiveDataState(visibilityState)),
      map(([, visibilityState]) => sensitiveDataToggleActions.syncSensitiveSuccessfully({ visibility: !visibilityState })),
    ),
  );

  handleSensitiveDataNotificationDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(sensitiveDataToggleActions.loadSensitiveDataOption),
      withLatestFrom(this.store.select(selectNotificationState)),
      map(([, notificationState]) =>
        sensitiveDataToggleActions.syncNotificationSuccessfully({
          dismissed: this.dataAdapter.loadNotificationState() || notificationState,
        }),
      ),
    ),
  );

  syncSensitiveDataNotificationDialog = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sensitiveDataToggleActions.dismissNotification),
        tap(() => this.dataAdapter.dismissNotificationState()),
      ),
    { dispatch: false },
  );
}
